import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Icon, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDoneAll } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Flex & Burn Spor Salonu
			</title>
			<meta name={"description"} content={"Fitness Hedeflerinin Gerçeklikle Buluştuğu Yer"} />
			<meta property={"og:title"} content={"Home | Flex & Burn Spor Salonu"} />
			<meta property={"og:description"} content={"Fitness Hedeflerinin Gerçeklikle Buluştuğu Yer"} />
			<meta property={"og:image"} content={"https://oldicombotru.com/img/523525.jpg"} />
			<link rel={"shortcut icon"} href={"https://oldicombotru.com/img/350flexedbiceps2_100913.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://oldicombotru.com/img/350flexedbiceps2_100913.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://oldicombotru.com/img/350flexedbiceps2_100913.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://oldicombotru.com/img/350flexedbiceps2_100913.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://oldicombotru.com/img/350flexedbiceps2_100913.png"} />
			<meta name={"msapplication-TileImage"} content={"https://oldicombotru.com/img/350flexedbiceps2_100913.png"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenu" />
		</Components.Header>
		<Section padding="140px 0 210px" sm-padding="40px 0 40px 0" background="linear-gradient(0deg,rgba(0, 0, 0, 0.6) 0%,rgba(0, 0, 0, 0.6) 100%),#000000 url(https://oldicombotru.com/img/3134654.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="560px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					Flex & Burn'e Hoş Geldiniz
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
					Flex & Burn Gym
				</Text>
				<Text font="--base">
					En iyi halinizi şekillendirmek için ter ve gücün birleştiği bir alan. Flex & Burn Gym, fitness için yerel sığınağınızdır ve geleneksel antrenman ile modern bir sağlıklı yaşam yaklaşımının bir karışımını sunar.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 20px 0px"
					color="--dark"
					font="normal 700 48px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Hakkımızda
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					Flex & Burn'de, yeni başlayanlardan sporculara kadar her seviyeye hitap edecek şekilde tasarlanmış kapsamlı bir fitness deneyimi sunuyoruz. Misyonumuz, her tekrarda ve her koşuda sizi motive eden, eğiten ve destekleyen bir ortam yaratmaktır.
				</Text>
			</Box>
		</Section>
		<Section color="--dark">
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Neden Flex & Burn'ü Seçmelisiniz?
			</Text>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdDoneAll}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Çeşitli Antrenman Seçenekleri
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					Egzersizlerinizi taze ve zorlu tutmak için çeşitli ekipman ve sınıflar.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdDoneAll}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Topluluk Ruhu
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					Moral veren ve ilham veren destekleyici bir fitness topluluğu.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdDoneAll}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Gizli Ücretler Yok
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					Şeffaf fiyatlandırma, sürpriz ücretler yok. Önceliğimiz sizin zindeliğinizdir, satış değil.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--secondary"
					category="md"
					icon={MdDoneAll}
				/>
				<Text as="h3" font="--headline3" margin="10px 0">
					Sağlığa Odaklanın
				</Text>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
					Fitness'ın bir yaşam tarzı olduğuna inanıyoruz ve spor salonumuz sağlığa dengeli bir yaklaşımı teşvik ediyor.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://oldicombotru.com/img/523525.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					max-height="380px"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Flex & Burn Gym - Her Egzersizin Kazanç Getirdiği Yer
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-secondary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Bize Ulaşın
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});